import React from 'react';

const Gratitudes = () => {
    const token = localStorage.getItem('h-access_token');

    const getMainDomain = (hostname) => {
        const parts = hostname.split('.');
        return parts.slice(-2).join('.');
    };
    const mainDomain = getMainDomain(window.location.hostname);
    const adminDomain = `admin.${mainDomain}`;
    const url = `https://${adminDomain}/my2/gratitudes?token=${token}`;

    return (
        <div>
            <iframe src={url} style={{width: '100%', height: 'calc(100vh - 80px)'}} title="Gratitudes"></iframe>
        </div>
    );
};

export default Gratitudes;
